import * as React from 'react';
import { useState } from 'react';

import ImageMoneyBoxSvgUrl from 'assets/images/pages/home/delivered/money-inbox.url.svg';
import TriangleBgSvgUrl2 from 'assets/images/pages/home/delivered/triangle-1.url.svg';
import TriangleBgSvgUrl3 from 'assets/images/pages/home/delivered/triangle-2.url.svg';
import TriangleBgSvgUrl1 from 'assets/images/pages/home/delivered/triangle-3.url.svg';
import TriangleBgSvgUrl4 from 'assets/images/pages/home/delivered/triangle-4.url.svg';

import DeliveredForm from './form/delivered-form';
import {
  Wrapper,
  TriangleWrapper,
  TriangleBg1,
  TriangleBg2,
  TriangleBg3,
  TriangleBg4,
  ImageMoneyBox,
  MoneyValue,
  Container,
  ContentWrapper,
  ContentHead,
  Content,
  FormWrapper,
  FormInner,
  FormWrapperImage,
  FormTitle,
  FormDescription,
  StyledTitle,
  SubTitle,
} from './home-delivered.styles';

export const Delivered = (props) => {
  const { title, description, formTitle, formDescription, formDescriptionSuccess } = props;
  const [rewardSent, setRewardSent] = useState(false);
  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <ContentHead>
            <StyledTitle as="h2">{title}</StyledTitle>
            <SubTitle>{description}</SubTitle>
          </ContentHead>
          <Content>
            <FormWrapper>
              <FormInner>
                <FormWrapperImage>
                  <ImageMoneyBox src={ImageMoneyBoxSvgUrl} alt="Amazon gift card" />
                  <MoneyValue>$0.01</MoneyValue>
                </FormWrapperImage>
                <FormTitle as="h3">{formTitle}</FormTitle>
                <FormDescription>
                  {rewardSent ? formDescriptionSuccess : formDescription}
                </FormDescription>
                <DeliveredForm setRewardSent={setRewardSent} />
              </FormInner>
            </FormWrapper>
          </Content>
        </ContentWrapper>
      </Container>
      <TriangleWrapper>
        <TriangleBg1 src={TriangleBgSvgUrl1} alt="" />
        <TriangleBg2 src={TriangleBgSvgUrl2} alt="" />
        <TriangleBg3 src={TriangleBgSvgUrl3} alt="" />
        <TriangleBg4 src={TriangleBgSvgUrl4} alt="" />
      </TriangleWrapper>
    </Wrapper>
  );
};
