import { graphql } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';

import Layout from 'components/_settings/layout';
import { Footer } from 'components/blocks/footer';
import { Header } from 'components/blocks/header';
import { Delivered } from 'components/pages/home/delivered';

import { buildPageMetadata } from '../utils/data-builders';

export const Space = styled.div`
  height: 100px;
  background: #fbfbfc;
`;

const InstantIncentives = (props) => {
  const {
    data: {
      wpPage,
      wpPage: { acf },
    },
  } = props;

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header />
      <Space />
      <Delivered
        title={acf.section2Title}
        description={acf.section2Description}
        formTitle={acf.section2FormTitle}
        formDescription={acf.section2FormDescription}
        formDescriptionSuccess={acf.section2FormDescriptionSuccess}
      />
      <Footer theme="light" />
    </Layout>
  );
};

export default InstantIncentives;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        section2Title
        section2Description
        section2FormTitle
        section2FormDescription
        section2FormDescriptionSuccess
      }
    }
  }
`;
