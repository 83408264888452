import styled from 'styled-components';

import { media, fontSize, color } from 'components/_settings/variables';
import { Container as SharedContainer } from 'components/shared/container';
import { Title } from 'components/shared/title';

export const Wrapper = styled.section`
  position: relative;
  padding-bottom: 206px;
  background-color: #fbfbfc;
  ${media.md} {
    margin-bottom: 0;
    padding-bottom: 168px;
  }
`;

export const Container = styled(SharedContainer)`
  position: relative;
  padding-top: 18px;
  padding-bottom: 75px;
  z-index: 1;
  ${media.md} {
    padding-top: 21px;
    padding-bottom: 43px;
  }
  /* parallax */
  /* transform: translateY(calc((var(--offset, 0) - 0.5) * 250px)); */
  ${media.md} {
    transform: none;
  }
`;

export const TriangleBg1 = styled.img`
  position: absolute;
  top: 26px;
  left: -237px;
  ${media.lg} {
    left: -357px;
  }
  ${media.md} {
    top: auto;
    bottom: 47px;
    left: -246px;
    height: 402px;
  }

  /* parallax */
  /* transform: translateY(calc((var(--offset, 0) - 0.5) * 500px)); */
  ${media.md} {
    transform: none;
  }
`;

export const TriangleBg2 = styled.img`
  position: absolute;
  bottom: 91px;
  left: 300px;
  ${media.md} {
    bottom: 142px;
    left: 168px;
  }
`;

export const TriangleBg3 = styled.img`
  position: absolute;
  top: 210px;
  right: 429px;
  ${media.lg} {
    right: 179px;
  }
  ${media.md} {
    top: 183px;
    right: -30px;
    height: 159px;
  }
  /* parallax */
  /* transform: translateY(calc((var(--offset, 0) - 0.5) * -200px)); */
  ${media.md} {
    transform: none;
  }
`;

export const TriangleBg4 = styled.img`
  position: absolute;
  top: 76px;
  right: -248px;
  ${media.md} {
    display: none;
  }
`;

export const ImageMoneyBox = styled.img`
  width: 120px;
`;

export const MoneyValue = styled.div`
  position: absolute;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: ${color.text.tertiary};
  border-radius: 7px;
  background-color: #2fcce4;
  margin-left: 37px;
  padding: 9px 5px;
`;

export const TriangleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const StyledTitle = styled(Title)`
  font-size: 42px;
  line-height: 1.29;
  text-align: center;
  margin: 36px 0 18px;
  white-space: pre-wrap;
  ${media.sm} {
    font-size: 32px;
    line-height: 1.25;
    margin: 36px 0 26px;
    white-space: initial;
  }
`;

export const SubTitle = styled.p`
  font-size: ${fontSize.md}
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  color: #6B7696;
  margin: 0;
  text-align: center;
  ${media.sm}{
    font-size: ${fontSize.sm};
    line-height: 1.5;
    color: #6B7696;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentHead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
  ${media.sm} {
    margin-bottom: 40px;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const FormWrapper = styled.div`
  position: relative;
  padding: 45px 49px 54px;
  width: 100%;
  max-width: 489.31px;
  border-radius: 16px;
  box-shadow: 14px 46px 180px -35px rgba(50, 50, 93, 0.89), 2px 2px 22px 0 rgba(26, 26, 67, 0.01);
  background-color: ${color.text.tertiary};
  ${media.sm} {
    padding: 36px 36px 33px;
  }
  &::before {
    content: '';
    position: absolute;
    border-radius: 8px;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: linear-gradient(122deg, #ebeafd 5%, #cbeaec 60%, #ffd9ec 114%);
    ${media.sm} {
      top: 14px;
      left: 15px;
      right: 15px;
      bottom: 13px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    border-radius: 7px;
    top: 22px;
    left: 22px;
    right: 22px;
    bottom: 22px;
    background-color: ${color.text.tertiary};
    ${media.sm} {
      top: 16px;
      left: 17px;
      right: 17px;
      bottom: 15px;
    }
  }
`;

export const FormInner = styled.div`
  position: relative;
  z-index: 1;
`;

export const FormWrapperImage = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const FormTitle = styled(Title)`
  font-size: ${fontSize.lg};
  line-height: 1.67;
  text-align: center;
  margin: -11px 0 8px;
  ${media.sm} {
    font-size: 20px;
    line-height: 1.4;
    margin: -2px 5px 21px;
  }
`;

export const FormDescription = styled.p`
  font-size: ${fontSize.sm};
  text-align: center;
  color: #6b7696;
  margin: 0 0 42px;
  ${media.sm} {
    font-size: ${fontSize.xs};
    line-height: 1.43;
    margin: 0 0 12px;
  }
`;
