import styled, { css } from 'styled-components';

import { media, color, fontSize, transition } from 'components/_settings/variables';
import { Button } from 'components/shared/button';
import { Field } from 'components/shared/field';
import { rgba } from 'utils';

export const Form = styled.form`
  position: relative;
  ${media.sm} {
    text-align: center;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

export const StyledField = styled(Field)`
  display: flex;
  flex-direction: column;
  text-align: center;
  ${media.sm} {
    margin-bottom: 10px;
  }
  input {
    padding-top: 22px;
    padding-right: 20px;
    padding-bottom: 22px;
    padding-left: 20px;
    &::placeholder {
      color: #838791;
    }
  }
  span {
    position: absolute;
    bottom: -20px;
    width: 100%;
    ${media.sm} {
      position: relative;
      bottom: auto;
    }
  }
`;

export const ButtonForm = styled.button`
  position: absolute;
  top: 12px;
  right: 19px;
  font-size: ${fontSize.xs};
  font-weight: bold;
  color: ${color.text.tertiary};
  background-color: ${color.primary};
  border: none;
  border-radius: 4px;
  padding-top: 9.5px;
  padding-bottom: 10.5px;
  padding-right: 33px;
  padding-left: 33px;
  outline: none;
  cursor: pointer;
  transition: ${transition.base};
  box-shadow: 0 10px 20px 0 rgba(61, 206, 228, 0.55);
  min-width: 125.63px;
  height: 47px;
  ${media.sm} {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 255px;
  }
  svg {
    height: 100%;
  }
  &:hover {
    transform: scale(1.02);
    background-color: ${rgba(color.primary, 0.9)};
  }
  &:focus,
  &:active {
    background-color: ${color.primary};
  }
`;

export const SuccessWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  background-color: #f7feff;
  height: 100%;
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  ${(p) => css`
    ${p.theme === 'accept' &&
    css`
      ${media.xs} {
        ${SuccessIcon} {
          display: none;
        }
      }
    `}
  `}
  ${(p) => css`
    ${p.theme === 'success' &&
    css`
      align-items: center;
    `}
  `}
`;

export const SuccessIcon = styled.div`
  margin-right: 16px;
`;

export const SuccessContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SuccessTitle = styled.span`
  font-size: ${fontSize.xs};
  font-weight: 500;
  color: #6b7696;
  line-height: 24px;
`;

export const SuccessFooter = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 136px);
  grid-gap: 10px;
  margin-top: 16px;
  ${media.xs} {
    grid-template-columns: 12fr;
  }
`;

export const SuccessButton = styled(Button)`
  span {
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  ${(p) => css`
    ${p.border &&
    css`
      border: 1px solid #00c1de;
      border-radius: 4px;
    `}
  `}
`;

export const Error = styled.span`
  position: absolute;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  width: 100%;
  bottom: -20px;
  color: #fa0309;
  text-align: center;
`;
