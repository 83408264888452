import { Formik } from 'formik';
import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Recaptcha from 'react-recaptcha';
import * as Yup from 'yup';

import ButtonLoaderSvg from 'components/icons/circle-loader.svg';
import { FREE_EMAIL_PROVIDER_DOMAINS } from 'constants/free-email-provider-domains';

import CheckSuccessSvg from './check.svg';
import {
  Form,
  ButtonForm,
  StyledField,
  SuccessIcon,
  SuccessWrapper,
  SuccessTitle,
  SuccessContent,
  SuccessFooter,
  SuccessButton,
  Error,
} from './delivered-form.styles';

const DeliveredForm = ({ setRewardSent }) => {
  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please, enter valid email')
      .required('Required')
      .test('is-whitelisted-email', 'This doesn’t appear to be a business email', (value) => {
        if (!value) return false;
        const [, emailDomain] = value.split('@');
        return !FREE_EMAIL_PROVIDER_DOMAINS.includes(emailDomain);
      }),
  });

  const [acceptForm, setAcceptForm] = useState(false);
  const [successForm, setSuccessForm] = useState(false);
  const [errorServer, setErrorServer] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  // create a variable to store the component instance reCAPTCHA
  const recaptchaInstance = useRef();

  const acceptSubmit = (accept) => {
    if (accept) {
      fetch(`${process.env.GATSBY_MAIL_API_URL}/gifts/contact-consent`, {
        method: 'POST',
        body: JSON.stringify({ email: userEmail }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    setAcceptForm(false);
    setSuccessForm(true);
  };
  return (
    <Formik
      initialValues={{ email: '', recaptcha: '' }}
      validationSchema={SignupSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        setUserEmail(values.email);
        if (!values.recaptcha) {
          recaptchaInstance.current.execute();
        } else {
          fetch(`${process.env.GATSBY_MAIL_API_URL}/gifts/welcome-gift-card`, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
              'Content-Type': 'application/json',
            },
          })
            .then((response) => {
              if (response.ok) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                  event: 'wegift-instant-incentive-submit',
                });
                setErrorServer(false);
                setAcceptForm(true);
                setRewardSent(true);
              } else {
                setErrorServer(true);
                throw new Error();
              }
            })
            .catch(() => {
              setSubmitting(false);
            });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          {!successForm && !acceptForm && (
            <StyledField
              type="email"
              name="email"
              label={false}
              placeholder="Your business email"
              value={values.email}
              error={touched.email && errors.email}
              readonly
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )}
          {errorServer && !errors.email && <Error>Internal server error, try again later</Error>}
          {acceptForm && (
            <SuccessWrapper theme="accept">
              <SuccessIcon>
                <CheckSuccessSvg />
              </SuccessIcon>
              <SuccessContent>
                <SuccessTitle>
                  All done! Are you happy for us to contact you about our products? We promise not
                  to spam you.
                </SuccessTitle>
                <SuccessFooter>
                  <SuccessButton
                    arrow={false}
                    type="button"
                    onClick={() => {
                      acceptSubmit(true);
                    }}
                  >
                    Yes
                  </SuccessButton>
                  <SuccessButton
                    theme="text"
                    border="true"
                    arrow={false}
                    type="button"
                    onClick={() => {
                      acceptSubmit(false);
                    }}
                  >
                    No
                  </SuccessButton>
                </SuccessFooter>
              </SuccessContent>
            </SuccessWrapper>
          )}
          {successForm && (
            <SuccessWrapper theme="success">
              <SuccessIcon>
                <CheckSuccessSvg />
              </SuccessIcon>
              <SuccessTitle>Thank you. Your preferences were updated.</SuccessTitle>
            </SuccessWrapper>
          )}
          <Helmet>
            <script src={process.env.GATSBY_RECAPTCHA_API_URL} async />
          </Helmet>
          <Recaptcha
            ref={recaptchaInstance}
            sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY_INVISIBLE}
            size="invisible"
            render="explicit"
            verifyCallback={(response) => {
              setFieldValue('recaptcha', response);
              handleSubmit();
            }}
            onloadCallback={() => {}}
          />
          {!successForm && !acceptForm && (
            <ButtonForm type="submit" disabled={isSubmitting} submitting={isSubmitting}>
              {isSubmitting ? <ButtonLoaderSvg /> : 'Show me'}
            </ButtonForm>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default DeliveredForm;
